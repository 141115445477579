import { advertiserBuilder } from './builders/advertiserBuilder';
import { advertiserRatingBuilder } from './builders/advertiserRatingsBuilder';
import { amenitiesBuilder } from './builders/amenitiesBuilder';
import { billsBuilder } from './builders/billsBuilder';
import { cityBuilder } from './builders/cityBuilder';
import { contractTypeBuilder } from './builders/contractTypeBuilder';
import { countryBuilder } from './builders/countryBuilder';
import { creationDateTSBuilder } from './builders/creationDateTSBuilder';
import { dateTSBuilder } from './builders/dateTSBuilder';
import { excludeAdvertiserBuilder } from './builders/excludeAdvertiserBuilder';
import { excludeUnitTypeBuilder } from './builders/excludeUnitTypeBuilder';
import { facilitiesBuilder } from './builders/facilitiesBuilder';
import { freePlacesBuilder } from './builders/freePlacesBuilder';
import { furnitureBuilder } from './builders/furnitureBuilder';
import { genderBuilder } from './builders/genderBuilder';
import { isSearchableBuilder } from './builders/isSearchableBuilder';
import { maximumStayBuilder } from './builders/maximumStayBuilder';
import { minimumStayMonthsBuilder } from './builders/minimumStayMonthsBuilder';
import { partnerBuilder } from './builders/partnerBuilder';
import { priceBuilder } from './builders/priceBuilder';
import { propertyBuilder } from './builders/propertyBuilder';
import { propertySizeBuilder } from './builders/propertySizeBuilder';
import { propertyTypeBuilder } from './builders/propertyTypeBuilder';
import { publishedDateTSBuilder } from './builders/publishedDateTSBuilder';
import { registrationBuilder } from './builders/registrationBuilder';
import { roomsBuilder } from './builders/roomsBuilder';
import { rulesBuilder } from './builders/rulesBuilder';
import { studapartBuilder } from './builders/studapartBuilder';
import { suitableForBuilder } from './builders/suitableForBuilder';
import { BuildersOverride, FilterBuilder } from './Types';

const defaultBuilders: BuildersOverride = {
  propertyIds: propertyBuilder,
  dateTS: dateTSBuilder,
  minimumStayMonths: minimumStayMonthsBuilder,
  propertyType: propertyTypeBuilder,
  propertySize: propertySizeBuilder,
  contractType: contractTypeBuilder,
  furniture: furnitureBuilder,
  advRating: advertiserRatingBuilder,
  bills: billsBuilder,
  registration: registrationBuilder,
  rules: rulesBuilder,
  gender: genderBuilder,
  suitableFor: suitableForBuilder,
  creationDateTS: creationDateTSBuilder,
  publishedDateTS: publishedDateTSBuilder,
  rooms: roomsBuilder,
  freePlaces: freePlacesBuilder,
  maximumStay: maximumStayBuilder,
  priceEUR: priceBuilder,
  isSearchable: isSearchableBuilder,
  advertiser: advertiserBuilder,
  country: countryBuilder,
  city: cityBuilder,
  facilities: facilitiesBuilder,
  amenities: amenitiesBuilder,
  partner: partnerBuilder,
  studapart: studapartBuilder,
  excludeAdvertiser: excludeAdvertiserBuilder,
  excludeUnitTypeIds: excludeUnitTypeBuilder,
};

export const filterBuilder =
  (builders: BuildersOverride = {}): FilterBuilder =>
  (params, context) => {
    const buildersSet = { ...defaultBuilders, ...builders };

    const filterParts: string[] = Object.values(buildersSet).map(fn =>
      fn(params, context),
    );

    return filterParts.filter(Boolean).join(' AND ');
  };
