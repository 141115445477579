import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { reducer as hermesReducer } from 'ha/modules/Hermes/reducer';
import { reducer as languageSwitcher } from 'ha/modules/LanguageSwitcher/reducer';
import { reducer as authLogic } from 'ha/modules/AuthLogic/reducer';
import { reducer as modalsLogic } from 'ha/modules/ModalsLogic/reducers';
import { STATE_NAME as MODALS_LOGIC } from 'ha/modules/ModalsLogic/constants';
import { uiReducer } from 'ha/modules/UI/reducer';
import {
  alertsReducer,
  STATE_NAME as ALERTS_STATE_NAME,
} from 'ha/modules/Alertbar';
import {
  savedSearchesReducer,
  STATE_NAME as SAVED_SEARCHES,
} from 'ha/modules/SavedSearches';
import {
  reducer as experimentsReducer,
  STATE_NAME as EXPERIMENTS_STATE_NAME,
} from 'ha/modules/Experiments';
import {
  STATE_NAME as FEATURED_CITIES,
  reducer as featuredCities,
} from 'ha/modules/FeaturedCities';
import {
  reducer as appSettingsReducer,
  STATE_NAME as APP_SETTINGS,
} from 'ha/modules/AppSettings';
import {
  reducer as favoriteListingReducer,
  STATE_NAME as FAVORITE_LISTING,
} from 'ha/modules/FavoriteListing';
import { STATE_NAME as INBOX_COUNTER } from 'ha/modules/InboxCounter/constants';
import { reducer as inboxCounterReducer } from 'ha/modules/InboxCounter/reducer';
import {
  STATE_NAME as FFNext,
  reducer as FFNextReducer,
} from 'ha/modules/FeatureFlags';
import { STATE_NAME as PAYMENT_PLAN_STATE_NAME } from 'ha/modules/Payments/PaymentPlan/constants';

import { STATE_NAME as LIST_YOUR_PAGE } from 'ha/pages/ListYourPlace/constants';
import { STATE_NAME as EDIT_LISTING_STATE_NAME } from 'ha/pages/ListingEdit/constants';
import { STATE_NAME as EDIT_LISTING_DRAFT_STATE_NAME } from 'ha/pages/ListingEditDraft/constants';
import { STATE_NAME as UNIVERSITIES_STATE_NAME } from 'ha/pages/Universities/constants';
import { STATE_NAME as PROFILE_EDIT_STATE_NAME } from 'ha/pages/ProfileEdit/constants';
import { STATE_NAME as VERIFICATION_STATE_NAME } from 'ha/pages/Verification/constants';
import { STATE_NAME as PAY_PAYMENT_REQUEST_STATE_NAME } from 'ha/pages/PayPaymentRequest/constants';
import { STATE_NAME as LISTINGS_STATE_NAME } from 'ha/pages/MyListings/constants';
import { STATE_NAME as CITIES_STATE_NAME } from 'ha/pages/Cities/constants';
import { STATE_NAME as BOOKING_STATE_NAME } from 'ha/pages/Booking/constants';
import { STATE_NAME as SEARCH_STATE_NAME } from 'ha/pages/Search/constants';
import { STATE_NAME as VERIFY_PHONE_NUMBER } from 'ha/pages/VerifyPhoneNumber/constants';
import { STATE_NAME as BILLING_STATE_NAME } from 'ha/pages/PayoutsBilling/constants';
import { STATE_NAME as MULTI_BILLING_STATE_NAME } from 'ha/pages/PayoutsMultiBilling/constants';
import { STATE_NAME as INVOICES_STATE_NAME } from 'ha/pages/PayoutsInvoices/constants';
import { STATE_NAME as PAYOUT_METHODS_STATE_NAME } from 'ha/pages/PayoutsPreferences/constants';
import { STATE_NAME as TALK_STATE_NAME } from 'ha/pages/Talk/constants/stateName';
import { STATE_NAME as TRANSACTION_HISTORY_STATE_NAME } from 'ha/pages/PayoutsHistory/constants';
import { STATE_NAME as PAYOUTS_PAYMENT_REQUESTS_STATE_NAME } from 'ha/pages/PayoutsPaymentRequests/constants';
import { STATE_NAME as CONTACT_ADVERTISER_STATE_NAME } from 'ha/pages/ContactAdvertiser/constants';
import { STATE_NAME as SEARCH_ALERT_LISTINGS_STATE_NAME } from 'ha/pages/SearchAlertListings/constants';
import { STATE_NAME as BILLING_SUBSCRIPTION_LANDING_STATE_NAME } from 'ha/pages/BillingSubscriptionLanding/reducer';

function createReducer(asyncReducers) {
  return combineReducers({
    ui: uiReducer,
    [ALERTS_STATE_NAME]: alertsReducer,
    hermes: hermesReducer,
    form: formReducer,
    languageSwitcher,
    authLogic,
    [MODALS_LOGIC]: modalsLogic,
    [SAVED_SEARCHES]: savedSearchesReducer,
    [FEATURED_CITIES]: featuredCities,
    [EXPERIMENTS_STATE_NAME]: experimentsReducer,
    [APP_SETTINGS]: appSettingsReducer,
    [FAVORITE_LISTING]: favoriteListingReducer,
    [INBOX_COUNTER]: inboxCounterReducer,
    [FFNext]: FFNextReducer,
    // Code splitting
    listing: state => state || {},
    [SEARCH_STATE_NAME]: state => state || {},
    userPage: state => state || {},
    sitemap: state => state || {},
    [CITIES_STATE_NAME]: state => state || {},
    [LISTINGS_STATE_NAME]: state => state || {},
    [PAY_PAYMENT_REQUEST_STATE_NAME]: state => state || {},
    [EDIT_LISTING_STATE_NAME]: state => state || {},
    [EDIT_LISTING_DRAFT_STATE_NAME]: state => state || {},
    [UNIVERSITIES_STATE_NAME]: state => state || {},
    [PROFILE_EDIT_STATE_NAME]: state => state || {},
    [VERIFICATION_STATE_NAME]: state => state || {},
    [BOOKING_STATE_NAME]: state => state || {},
    [VERIFY_PHONE_NUMBER]: state => state || {},
    [BILLING_STATE_NAME]: state => state || {},
    [MULTI_BILLING_STATE_NAME]: state => state || {},
    [INVOICES_STATE_NAME]: state => state || {},
    [PAYOUT_METHODS_STATE_NAME]: state => state || {},
    [TALK_STATE_NAME]: state => state || {},
    [TRANSACTION_HISTORY_STATE_NAME]: state => state || {},
    [PAYOUTS_PAYMENT_REQUESTS_STATE_NAME]: state => state || {},
    [CONTACT_ADVERTISER_STATE_NAME]: state => state || {},
    [SEARCH_ALERT_LISTINGS_STATE_NAME]: state => state || {},
    [PAYMENT_PLAN_STATE_NAME]: state => state || {},
    [BILLING_SUBSCRIPTION_LANDING_STATE_NAME]: state => state || {},
    [LIST_YOUR_PAGE]: state => state || {},
    country: state => state || {},
    ...asyncReducers,
  });
}

const createAppReducer = asyncReducers => createReducer(asyncReducers);
export { createAppReducer };
