import { defineKey as T } from '@ha/intl';

export const STATE_NAME = 'myListings';
export const LISTINGS_PER_PAGE = 20;
export const FIRST_PAGE_NUM = 1;
export const MODAL_FILTERS_MOBILE = 'listingsMobileFilters';
export const MESSAGES = {
  DUPLICATE_LISTING_ERROR: T(`We could't duplicate your listing.`),
};

export enum ActionType {
  Edit = 'edit',
  Publish = 'publish',
}
