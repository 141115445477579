import { ApiError } from './errors';

export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,

  PERMANENT_REDIRECTION = 301,
  FOUND_REDIRECTION = 302,
  NOT_MODIFIED = 304,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  GONE = 410,
  PRECONDITION_FAILED = 412,
  EXPECTATION_FAILED = 417,
  ENTITY_TOO_LARGE = 413,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  SERVICE_UNAVAILABLE = 503,
}

/**
 * matches JSON payload content type header:
 *   - `application/json`
 *   - `application/json; charset=utf-8`
 *   - `application/vnd.housinganywhere.moneta.v1 + json; charset=utf-8` (from moneta)
 */
const JSON_CONTENT_TYPE_RE =
  /^application\/(?:vnd\.housinganywhere\.moneta\.v1 \+ )?json(?:;.*)?$/;

export function handleApiV2Response(response: Response) {
  const { ok, status, statusText, headers } = response;

  return Promise.resolve()
    .then(() => {
      if (status === HttpStatus.NO_CONTENT) {
        return undefined;
      }

      if (status === HttpStatus.ENTITY_TOO_LARGE) {
        throw new ApiError(status, statusText, {}, headers || new Headers());
      }

      if (JSON_CONTENT_TYPE_RE.test(headers.get('content-type') || '')) {
        return response.json();
      }

      return undefined;
    })
    .then(data => {
      if (!ok) {
        throw new ApiError(
          status,
          statusText,
          data || {},
          headers || new Headers(),
        );
      }

      return { status, data, headers };
    });
}
