/* eslint-disable @typescript-eslint/no-throw-literal */
import React from 'react';

import {
  RouteObject,
  redirect,
  NonIndexRouteObject,
  IndexRouteObject,
} from 'react-router-dom';

import { Language } from '@ha/intl';

import { PRICING_SLUG } from 'ha/constants/PricingPageSlug';

import { getFeatureFlags, isEnabled } from 'ha/modules/FeatureFlags';
import { removeTrailingSlash } from 'ha/utils/urls/removeTrailingSlash';

import { getCurrentLanguageCode } from 'ha/modules/LanguageSwitcher/selectors';

import { getPrivateLandlordPageRoutes } from './constants/PrivateLandlordLandingPageSlug';
import { getIsAdvertiser } from './modules/AuthLogic/selectors';
import {
  InjectAsyncReducer,
  PageLoaderContext,
  PageLoaderFunction,
} from './types/routing';
import { notEmpty } from './utils/notEmpty';
import { LazyPageComponent } from './utils/routes/LazyPageComponent';
import { authenticated as createAuthenticatedLoader } from './utils/routes/loaders/authenticated';
import {
  contentfulAndCitiesPages,
  ContentfulAndCitiesPagesComponent,
} from './utils/routes/loaders/contentfulAndCitiesPages';
import { createMyPagesGuardLoader } from './utils/routes/loaders/myPagesGuard';
import { createRolesGuardLoader } from './utils/routes/loaders/rolesGuard';
import {
  communityRedirectedRoutes,
  localizedListOfRedirectedRoutes,
  nonLocalizedListOfRedirectedRoutes,
  RedirectRoute,
} from './utils/routes/redirectedRoutes';
import { RouterErrorBoundary } from './utils/routes/RouterErrorBoundary';
import { removeLangPrefix } from './utils/urls/removeLangPrefix';

export const createRoutes = ({
  config,
  intl,
  services,
  store,
  logger,
}: PageLoaderContext): RouteObject[] => {
  const customLoaderContext: PageLoaderContext = {
    config,
    intl,
    services,
    store,
    logger,
  };

  const {
    newBookingPages,
    enableSearchAlertsWithoutAuth,
    enableIdentityVerification,
    enableReplyAutomations,
  } = getFeatureFlags(store.getState());

  const isAdvertiser = getIsAdvertiser(store.getState());

  const { lang } = intl;

  const createPageRoute = (
    pageName: string,
    routeProps?:
      | Omit<NonIndexRouteObject, 'element'>
      | Omit<IndexRouteObject, 'element'>,
  ): RouteObject => {
    return {
      element: <LazyPageComponent pageName={pageName} store={store} />,
      ...routeProps,
      loader: async context => {
        if (routeProps?.loader) {
          await routeProps.loader(context);
        }

        const module = await import(`ha/pages/${pageName}/index.ts`);

        if (module.injectAsyncReducer) {
          (module.injectAsyncReducer as InjectAsyncReducer)(store);
        }

        if (module.loader) {
          const data = await (module.loader as PageLoaderFunction)({
            ...context,
            context: customLoaderContext,
          });

          if (data !== undefined) {
            return data;
          }
        }

        // always return something in order to execute loader only once when ssr is enabled
        return null;
      },
    };
  };

  const createRedirectRoute = (redirectRoute: RedirectRoute): RouteObject => ({
    path: redirectRoute.path,
    loader: loaderArgs => {
      const newUrl = redirectRoute.getNextPath(loaderArgs);

      return redirect(newUrl, redirectRoute.redirectCode ?? 301);
    },
  });

  const authenticated = createAuthenticatedLoader(store);
  const myPagesGuard = createMyPagesGuardLoader({ store, intl });
  const rolesGuard = createRolesGuardLoader(store);

  const currentLanguage = getCurrentLanguageCode(store.getState());
  const pricingSlug = encodeURIComponent(PRICING_SLUG[currentLanguage]);

  const nonLocalizedRoutes: Array<RouteObject | undefined> = [
    ...nonLocalizedListOfRedirectedRoutes(
      lang === Language.en ? '/' : `/${lang}/`,
    ).map(createRedirectRoute),
    createPageRoute('Home', { index: true }),
    createPageRoute('Search', { path: 's/:location/:localizedKind?' }),
    createPageRoute('Listing', { path: 'room/:id/:countryCode/:city/*' }),
    createPageRoute('UserPage', { path: 'users/show/:id' }),
    createPageRoute('ActivateAccount', { path: 'activate-account/:token' }),
    createPageRoute('SmsAccept', { path: 'sms-accept/:token' }),
    createPageRoute('Shortlink', { path: 'h/:token' }),
    createPageRoute('Pricing', { path: `${pricingSlug}/:userType` }),
    createPageRoute('Careers', { path: 'careers/:subPath?' }),
    createPageRoute('BrowserNotSupported', { path: 'browser-not-supported' }),
    createPageRoute('Login', {
      path: 'login',
      loader: authenticated,
    }),
    createPageRoute('Register', {
      path: 'register',
      loader: authenticated,
    }),
    createPageRoute('RegisterPartner', {
      path: 'register/:partnerId',
    }),
    createPageRoute('ResetPassword', {
      path: 'reset-password/:token',
      loader: authenticated,
    }),
    createPageRoute('Answers', { path: 'answers' }),
    createPageRoute('ContactUs', { path: 'contact' }),
    createPageRoute('CookiePolicy', { path: 'cookie-policy/:subPath?' }),
    createPageRoute('PrivacyPolicy', { path: 'privacy-policy' }),
    createPageRoute('Legal', { path: 'legal' }),
    createPageRoute('ListYourPlace', { path: 'list-your-place' }),
    ...getPrivateLandlordPageRoutes().map(path =>
      createPageRoute('PrivateLandlordLanding', { path }),
    ),
    createPageRoute('RecoverAccount', {
      path: 'recover-account',
      loader: authenticated,
    }),
    createPageRoute('SafePaymentService', { path: 'safe-payment-service' }),
    createPageRoute('Terms', { path: 'de/agb' }),
    createPageRoute('Terms', { path: 'terms' }),
    createPageRoute('TenantReviewPolicy', { path: 'tenant-review-policy' }),
    createPageRoute('Universities', { path: 'universities' }),
    createPageRoute('Sitemap', { path: 'sitemap' }),
    createPageRoute('UnderReview', { path: 'under-review' }),
    createPageRoute('PaymentPlanAccept', {
      path: 'payment-plan/:token/:state?',
    }),
    createPageRoute('BillingSubscriptionLanding', {
      path: 'subscription/:handle',
    }),
    createPageRoute('BillingSubscriptionActivate', {
      path: 'subscription/:handle/activate',
      loader: myPagesGuard,
    }),
    createPageRoute('PayPaymentRequest', {
      path: 'r/pay/:handle',
      loader: myPagesGuard,
    }),
    createPageRoute('BookingLink', { path: 'link/:listingIdEncoded' }),
    isEnabled(enableSearchAlertsWithoutAuth)
      ? createPageRoute('SearchAlertUnsubscribe', {
          path: 'alert/:alertID/unsubscribe',
        })
      : undefined,
    {
      path: 'my',
      loader: myPagesGuard,
      children: [
        createPageRoute('UserDashboard', {
          index: true,
        }),
        createPageRoute('QuickRepliesSettings', {
          path: 'inbox/quick-replies-settings',
        }),
        createPageRoute('ContactAdvertiser', {
          path: 'book/contact/:listingId/:currentStep?',
        }),
        createPageRoute(isAdvertiser ? 'InboxAdvertiser' : 'Inbox', {
          path: 'inbox',
        }),
        ...(isEnabled(enableReplyAutomations)
          ? [
              createPageRoute('InboxAutomationSettings', {
                path: 'inbox/automation-settings',
              }),
            ]
          : []),
        createPageRoute('Bookings', { path: 'bookings' }),
        createPageRoute('MyListings', { path: 'listings' }),
        createPageRoute('ListingsSharedSettings', {
          path: 'listings/settings',
        }),
        createPageRoute('ListingEdit', { path: 'listings/:id/edit' }),
        createPageRoute('ListingEditDraft', {
          path: 'listings/:id/edit-draft',
        }),
        createPageRoute('AvailabilityChecker', {
          path: 'listings/:id/availability-status/:status',
        }),
        createPageRoute('ShareRoom', { path: 'listings/:id/success' }),
        createPageRoute('ShareRoom', { path: 'share-room/:id' }),
        createPageRoute('ListingCalendar', { path: 'listings/:id/calendar' }),
        createPageRoute('ProfileEdit', { path: 'profile/edit' }),
        createPageRoute('Talk', { path: 'talk/:id' }),
        createPageRoute(newBookingPages === 'on' ? 'Pay' : 'Booking', {
          path: 'talk/:id/:mode',
        }),
        {
          ...(newBookingPages === 'on' &&
            createPageRoute('PayConfirm', {
              path: 'talk/:id/confirm',
            })),
        },
        createPageRoute('Verification', { path: 'get-verified' }),
        createPageRoute('VerifyPhoneNumber', { path: 'verify-phone-number' }),
        createPageRoute('FavoriteListings', { path: 'favorites' }),
        createPageRoute('SearchAlertListings', { path: 'searches' }),
        createPageRoute('SurveyReview', { path: 'surveys/:id' }),
        createPageRoute('Subscriptions', { path: 'subscriptions' }),
        createPageRoute('ConfirmProfile', {
          path: 'profile/:userID/confirm/:token',
        }),
        createPageRoute('ConfirmPayoutMethod', {
          path: 'profile/:userID/pm/confirm/:token',
        }),
        createPageRoute('Pay', {
          path: 'payment/:id/:mode',
        }),
        createPageRoute('PayPaymentRequest', {
          path: 'pay/:handle/:type?',
        }),
        createPageRoute('PaymentMethods', { path: 'payments/methods' }),
        createPageRoute('PaymentsPaymentRequests', {
          path: 'payments/requests',
        }),
        createPageRoute('PayoutsPreferences', { path: 'payouts/preferences' }),
        createPageRoute('PayoutRouting', {
          path: 'payouts/preferences/:payoutMethodId/routing',
        }),
        createPageRoute('PayoutsHistory', { path: 'payouts/history' }),
        createPageRoute('PayoutsInvoices', { path: 'payouts/invoices' }),
        createPageRoute('PayoutsPlanPlaceholder', { path: 'payouts/plan' }),
        createPageRoute('PayoutsMultiBilling', {
          path: 'payouts/billing',
        }),
        createPageRoute('PayoutsPaymentRequests', {
          path: 'payouts/payment-requests',
        }),
        createPageRoute(
          isEnabled(enableIdentityVerification)
            ? 'VerifyIdentityNext'
            : 'VerifyIdentity',
          {
            path: 'payouts/verify-identity',
            loader: rolesGuard(['ADVERTISER']),
          },
        ),
        createPageRoute('PaymentPlan', { path: 'payment-plan' }),
        createPageRoute('PaymentPlan', {
          path: 'payment-plan/:conversationId',
        }),
        createPageRoute('TenantReviewDashboard', { path: 'tenant-reviews' }),
        createPageRoute('TenantReviewOverview', {
          path: 'tenant-reviews/:tenancyUUID',
        }),
        createPageRoute('PaymentAndLegal', {
          path: 'payment-and-legal/:unitTypeId',
        }),
        createPageRoute('TrustPilotFeedbackReview', {
          path: 'trustpilot-feedback',
        }),
      ],
    },
  ];

  const nonLocalizedRoutesFiltered = nonLocalizedRoutes.filter(notEmpty);

  const localizedRoutes: RouteObject[] = [
    ...nonLocalizedRoutesFiltered,
    ...localizedListOfRedirectedRoutes.map(createRedirectRoute),
    ...communityRedirectedRoutes.map(createRedirectRoute),
    {
      // insert the actual language instead of param
      // so the route resolution works correctly
      // i.e. for contentful pages like /community there is no way
      // to differentiate whether it's a ':lang' or '*' route
      // we can use param when react-router allows to limit param values
      path: lang,
      children: nonLocalizedRoutesFiltered,
      loader: context => {
        const { request } = context;
        const url = new URL(request.url);
        if (lang === Language.en) {
          return redirect(removeLangPrefix(url.pathname), 301);
        }
        if (url.pathname === `/${lang}`) {
          return redirect(`/${lang}/`, 301);
        }
        return null;
      },
    },
    {
      path: '*',
      loader: args =>
        contentfulAndCitiesPages({ ...args, context: customLoaderContext }),
      element: <ContentfulAndCitiesPagesComponent />,
    },
  ];

  return [
    {
      errorElement: <RouterErrorBoundary />,
      loader: ({ request }) => {
        const url = new URL(request.url);
        // trailing slash is only allowed when url ends in language
        if (
          url.pathname.length > 1 &&
          url.pathname.endsWith('/') &&
          url.pathname !== `/${lang}/`
        ) {
          return redirect(removeTrailingSlash(url.pathname), 301);
        }
        return null;
      },
      children: localizedRoutes,
    },
  ];
};
