import { createMemoryRouter } from 'react-router-dom';

import thunk, { ThunkMiddleware } from 'redux-thunk';

import { AppServices } from 'ha/services/getAppServices';

import { persistentMiddleware as alerts } from 'ha/modules/Alertbar/middleware';
import { inboxCounterMiddleware } from 'ha/modules/InboxCounter/middleware';
import { newTabMiddleware } from 'ha/modules/NewTabHandler';
import { realtimeMessagingMiddleWare } from 'ha/modules/RealtimeMessaging/middleware';
import { urlLocationMiddleware } from 'ha/modules/URLLocationHandler';
import { realtimeMiddleware as talkRealtimeMiddleware } from 'ha/pages/Talk/middlewares/realtime';

import { getChannelId } from './getChannelId';
import { createCrashReporter as crashReporter } from './middlewares/crashReporter';
import { queryParamsMiddleware } from './middlewares/queryParams';
import { scrollMiddleware as scroll } from './middlewares/scroll';
import { sanitizeState } from './sanitizeState';

export const skipVoidAction: ThunkMiddleware = _store => next => action => {
  if (action) return next(action);
};

const createAppMiddlewares = ({
  debug,
  services,
  routerRef,
}: {
  debug: boolean;
  services: AppServices;
  routerRef: {
    current: ReturnType<typeof createMemoryRouter> | null;
  };
}) => {
  const commonMiddlewares = [thunk.withExtraArgument(services), skipVoidAction];

  if (!process.browser) {
    return commonMiddlewares;
  }

  return [
    ...commonMiddlewares,
    urlLocationMiddleware(),
    newTabMiddleware(),
    queryParamsMiddleware({ routerRef }),
    crashReporter(sanitizeState),
    alerts,
    scroll,
    realtimeMessagingMiddleWare({
      debug,
      getChannelId,
    }),
    talkRealtimeMiddleware,
    inboxCounterMiddleware,
  ];
};
export { createAppMiddlewares };
